.question-paper {
    background-color: var(--theme-black);
    height: auto;
    h1, h2, h3, h4, h5, h6, p, span, li{
        font-family: ui-sans-serif !important;
    }
    h1, h2, h3, h4{
        font-weight: 600;
    }
    .container-fluid{
        max-width: 50%;
        padding: 30px;
        background-color: var(--white-color);
        .top-area {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
        }
        .subject-area{
            text-align: center;
            & > *{
                margin-bottom: 5px;
            }
        }
        .exam-details-area{
            display: flex;
            justify-content: space-between;
            & > *{
                margin-bottom: 0px;
            }
            p{
                font-weight: 600;
                font-style: italic;
            }
        }
        .instruction-area{
            margin-bottom: 1rem;
            & > *{
                margin-bottom: 0px;
            }
            p, li{
                font-weight: 600;
                font-style: italic;
            }
        }
        .question-area{
            .inner{
                display: flex;
                justify-content: space-between;
                margin-block-end: 1rem;
                .question-area-left > *, .question-area-right > *{
                    margin-bottom: 0px;
                }
                .question-area-left{
                    max-width: 85%;
                }
                .question-area-right{
                    p{
                        font-weight: 600;
                    }
                }
            }
        }
    }
}
.left-create-area {
  .filter-floating-box {
    .accordion-body {
      background-color: var(--theme-lighter-red);
    }
  }
  .question-floating-box {
    .accordion-body {
      background-color: var(--theme-lighter-red);
      ul {
        padding: 0 15px;
        list-style: lower-alpha;
        // min-height: 100vh;
        // max-height: 100%;
        .shifted {
          color: var(--light-gray);
        }
        li{
          span{
            display: inline-block;
            cursor: pointer;
            vertical-align: middle;
            margin-left: 5px;
          }
        }
      }
      & > ul {
        padding: 0 15px;
        list-style: decimal;
        li {
          margin: 15px 0;
          font-size: 0.9rem;
          line-height: 1.3rem;
          cursor: pointer;
        }
      }
    }
  }
  .instruction-floating-box {
    .accordion-body {
      background-color: var(--theme-lighter-red);
    }
  }
}