.form-group {
  font-size: 12px;
}
.placeholder-section{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height:100px;
  h5{
      text-transform: uppercase;
      text-align: center;
      color: #d5d5d5;
      text-wrap: wrap;
      letter-spacing: .5px;
      font-size: 1.854rem;
      font-weight: 700;
      line-height: 2.254rem;
      text-shadow: 1px -2px 3px #acacac;
  }
  img{
      width: 20%;
      filter: opacity(0.5);
  }
}
.left__{
  // border-right: 1px solid var(--lighter-gray);
        // width: 70%;
        // height: 100px;
        padding-right: 15px;
        position: sticky;
        top: 15px;
}

.title{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  h5{
    margin-bottom: 0px;
    text-transform: uppercase;
    text-align: center;
    color: #ee9a88;
    text-wrap: wrap;
    letter-spacing: .5px;
    font-size: 1.854rem;
    font-weight: 700;
    line-height: 2.254rem;
    text-shadow: 1px -2px 3px #acacac;
  }
}



.blur-background {
  filter: blur(5px);
  pointer-events: none;
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba(173, 216, 230, 0.3); 
  z-index: 1000;
}

.loading-overlay h5 {
  margin-top: 10px;
  font-size: 16px;
}
