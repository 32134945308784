.topbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 0;
  @media (max-width: 767px){
    flex-wrap: wrap;
    gap: 1rem;
  }
  .left-side {
    display: flex;
    align-items: center;
    gap: 1rem;
    & > * {
      margin-bottom: 0;
      cursor: pointer;
    }
    p.subject-name {
      background-color: var(--lighter-gray);
      text-align: center;
      // border-radius: 50px;
      position: relative;
      padding: 0px 20px;
      flex: 1;
      min-width: fit-content;
      &::after {
        content: '';
        background-color: var(--theme-red);
        width: 5px;
        height: 5px;
        border-radius: 50%;
        position: absolute;
        right: 5px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
    .search-div {
      position: relative;
      .search-icon {
        position: absolute;
        top: 50%;
        left: 5px;
        transform: translateY(-50%);
        img{
            width: 20px;
            height: 20px;
            vertical-align: sub;
        }
      }
      input {
        border-radius: .375rem;
        padding: 10px 30px;
        height: 40px;
        &::placeholder {
          color: var(--light-gray);
        }
        &:focus {
          box-shadow: none;
          border: 1px solid var(--theme-red);
        }
      }
    }
  }
  .right-side {
    display: flex;
    align-items: center;
    gap: 10px;
    @media (max-width: 767px){
      justify-content: flex-end;
      width: 100%;
    }
    .notification-menu {
      position: relative;
      cursor: pointer;
      &::before {
        content: '';
        position: absolute;
        background-color: var(--theme-red);;
        width: 5px;
        height: 5px;
        border-radius: 50%;
        top: 1px;
        right: 1px;
      }
    }
    .user-menu{
      cursor: pointer;
    }
  }
}

.form_required{
  color: red;
}


.form-group {
  .error {
    color: red;
    font-size: 12px;
  }
}

.auth-page {
  // background-color: var(--lightest-gray);
  background-image: url(https://png.pngtree.com/thumb_back/fh260/background/20201223/pngtree-yellow-simple-fresh-watercolor-education-background-image_512112.jpg);
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  min-height: 100vh;
  max-height: 100%;
  display: flex;
  position: relative;
  &::before{
    content: '';
    background-color: #0000003b;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: block;
  }
  .login-box {
    background-color: #ffffffad;
    z-index: 1;
    border-radius: 10px;
    width: 450px;
    padding: 30px;
    img{
      margin: 0px auto 15px;
      filter: invert(1);
      width: 25%;
    }
    h4,
    p {
      text-align: center;
      margin-bottom: 0;
    }
  }
}
